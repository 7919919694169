import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  Badge,
  Carousel,
  Accordion,
  Card,
  Button,
  Image,
  Modal,
  Tabs,
  Tab,
  Spinner,
} from "react-bootstrap";
import { Label, Input } from "reactstrap";
import BeautyStars from "beauty-stars";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  FaCheck,
  FaCalendar,
  FaMapMarkerAlt,
  FaUsers,
  FaMapMarkedAlt,
  FaUserAlt,
  FaCircle,
  FaChevronDown,
  FaChevronUp,
  FaHandshake,
  FaUserFriends,
  FaChild,
} from "react-icons/fa";
import { GoPerson } from "react-icons/go";
import { IoIosMan } from "react-icons/io";
import { ImManWoman } from "react-icons/im";
import { TiGroup } from "react-icons/ti";
import { RiGroupLine } from "react-icons/ri";
import { FaTimes } from "react-icons/fa";
import { FiMail, FiHelpCircle } from "react-icons/fi";
import { GiFoodTruck } from "react-icons/gi";
import { FaHome } from "react-icons/fa";
import { FaOpera } from "react-icons/fa";
import { BsGeoAlt } from "react-icons/bs";
import { MdLocalActivity } from "react-icons/md";
import { BiTime } from "react-icons/bi";
import Api from "../Service/Api";
import moment from "moment";
import ViewEvent from "./ViewEvent";
import ButtonCommon from "./ButtonCommon";
import Loader from "./Loader";
import SimilarAdventures from "../Components/SimilarAdventures";
import senior from "../../static/Images/senior-icon.png";
import { connect } from "react-redux";

class EventDetailsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kitlist: [],
      kitlistcalled: false,
      itinerariescalled: false,
      itineraries: [],
      includes: [],
      includescalled: false,
      recommends: [],
      recommendscalled: false,
      additionals: [],
      additionalscalled: false,
      faqs: [],
      faqscalled: false,
      banner: "",
      endDate: "",
      startDate: "",
      limit: 10,
      skip: 0,
      eventid: "",
      value: 0,
      eventdatelist: [],
      id: "",
      rating: [],
      faq: [],
      fav: false,
      prevfav: true,
      validated: false,
      validated1: false,
      modalShow: false,
      modalShow2: false,
      question: "",
      avgrate: "",
      email: "",
      phone: "",
      eventDateId:
        this.props &&
        this.props.history &&
        this.props.history.location &&
        this.props.history.location.id,
      up: false,
      down: true,
      up1: false,
      down1: true,
      up2: false,
      down2: true,
      up3: false,
      down3: true,
      up4: false,
      down4: true,
      up5: false,
      down5: true,
      up6: false,
      down6: true,
      up7: false,
      down7: true,
      name: "",
      emailFrom: "",
      emailTo: "",
      eventId: "",
      disblebutton: true,
      eventDateId: "",
      seatsavailable: true,
    };
    let current = false;
  }

  componentDidMount() {
    // this.getAllEventDate();
    // this.getratinglist();
    // this.getAvgRating();
    this.setData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== this.state.eventId) {
      window.location.reload();
      this.setData();
    }
  }

  setData = () => {
    let props = this.props;
    this.setState({
      eventId: props && props.id,
    });

    this.getAllEventDate();
    this.getratinglist();
    this.getAvgRating();
  };

  getratinglist = () => {
    Api.get(`/events/${this.props.id}/reviews?relations=["user"]`)
      .then((res) => {
        this.setState({ rating: res.data });
      })
      .catch((err) => {});
  };

  getAvgRating = () => {
    Api.get(`/events/${this.props.id}/reviews/average`)
      .then((res) => {
        this.setState({ avgrate: res.data });
      })
      .catch((err) => {});
  };

  getKitlist = () => {
    if (!this.state.kitlistcalled)
      Api.get(`/events/${this.props.id}/kits`)
        .then((res) => {
          this.setState({ kitlist: res.data, kitlistcalled: true });
        })
        .catch((err) => {});
  };
  getitineraries = () => {
    if (!this.state.itinerariescalled)
      Api.get(`/events/${this.props.id}/itineraries`)
        .then((res) => {
          this.setState({ itineraries: res.data, itinerariescalled: true });
        })
        .catch((err) => {});
  };
  getincludes = () => {
    if (!this.state.includescalled)
      Api.get(`/events/${this.props.id}/includes`)
        .then((res) => {
          this.setState({ includes: res.data, includescalled: true });
        })
        .catch((err) => {});
  };
  getrecommends = () => {
    if (!this.state.recommendscalled)
      Api.get(`/events/${this.props.id}/recommends`)
        .then((res) => {
          this.setState({
            recommends: res.data && res.data[0],
            recommendscalled: true,
          });
        })
        .catch((err) => {});
  };
  getadditionals = () => {
    if (!this.state.additionalscalled)
      Api.get(`/events/${this.props.id}/additionals`)
        .then((res) => {
          this.setState({
            additionals: res.data,
            additionalscalled: true,
          });
        })
        .catch((err) => {});
  };
  getfaqs = () => {
    if (!this.state.faqscalled)
      Api.get(`/events/${this.props.id}/faqs`)
        .then((res) => {
          this.setState({
            faqs: res.data,
            faqscalled: true,
          });
        })
        .catch((err) => {});
  };

  getAllEventDate = () => {
    Api.get(
      `/events/${this.props.id}/event-dates?relations=["bmtEvent","availability"]`
    )
      .then((res) => {
        if (res.data.length != 0)
          this.setState({ eventDateId: res.data[0].id });
        let disable;
        let seatsavail;
        res.data.map((item) => {
          var a = moment(new Date(), "HH:mm:ss");
          var b = moment(new Date(item.startDate), "HH:mm:ss");

          if (a.diff(b, "hours") >= 0) disable = true;
          else disable = false;

          if (item.available === 0) {
            // disable = true
            seatsavail = false;
          } else {
            // disable = false
            seatsavail = true;
          }
        });
        this.setState({
          eventdatelist: res.data,
          disblebutton: disable,
          seatsavailable: seatsavail,
        });
      })
      .catch((err) => {});
  };

  emailthistour = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ validated: true });
    if (form.checkValidity() === true) {
      event.preventDefault();
      this.setState({ loader: true });
      let data = {
        eventId: this.props.id ? parseInt(this.props.id, 10) : null,
        email: this.state.email,
      };

      Api.post(`/misc/email-bmt-event`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 201) {
            alert(
              "Thank you for showing interest in BookMyTrek!. The email has been sent successfully"
            );
            this.setState({ modalShow2: false, loader: false });
            this.setState({ email: "" });
          }
        })
        .catch((err) => {
          //  console.log(err.response)
        });
    }
  };

  askQue = (event) => {
    let accesstoken = localStorage.getItem("accesstoken");
    const token = `Bearer ${accesstoken}`;
    const form = event.currentTarget;
    this.setState({ question: "" });
    this.setState({ name: "" });
    this.setState({ phone: "" });
    this.setState({ email: "" });
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ validated: true });
    if (form.checkValidity() === true) {
      event.preventDefault();
      this.setState({ loader2: true });
      let data = {
        question: this.state.question,
        name: this.state.name,
        phone: this.state.phone ? parseInt(this.state.phone, 10) : null,
        email: this.state.email,
      };
      Api.post(`/events/${this.props.id}/user-queries`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 201) {
            alert(
              "Thank you for showing interest in BookMyTrek!. Our team will update status by mail "
            );

            this.setState({ modalShow: false, loader2: false });
            this.setState({ question: "" });
            this.setState({ name: "" });
            this.setState({ phone: "" });
            this.setState({ email: "" });
          }
        })
        .catch((err) => {
          // console.log(err.response);
        });
    }
  };

  sendEmail = (event) => {
    let accesstoken = localStorage.getItem("accesstoken");
    const token = `Bearer ${accesstoken}`;
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ validated: true });
    if (form.checkValidity() === true) {
      event.preventDefault();

      this.setState({ loader: true });
      let data = {
        emailFrom: this.state.emailFrom,
        emailTo: this.state.emailTo,
        name: this.state.name,
        eventId: this.props.id ? parseInt(this.props.id, 10) : null,
      };

      Api.post(`/misc/email-friend-bmt-event`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 201) {
            alert(
              "Thank you for showing interest in BookMyTrek!.The email has been sent successfully"
            );
            this.setState({ modalShow2: false, loader: false });
            this.setState({ emailFrom: "", emailTo: "", name: "" });
          }
        })
        .catch((err) => {
          // console.log(err.response);
        });
    }
  };

  daydiff = () => {
    const { list } = this.props;

    var a = moment(list && list.endDate);
    var b = moment(list && list.startDate);
    // return a.diff(b, "days");
    var c = a.diff(b, "days") + 1;
    return c;
  };
  percen = (list) => {
    var number = list && list.amount;
    var percentToGet = list && list.discount; //Calculate the percent.
    var percent = (percentToGet / 100) * number;
    return percent;
  };
  render() {
    const { image, place } = this.props;
    let {
      banner,
      endDate,
      startDate,
      eventdatelist,
      rating,
      fav,
      prevfav,
      recommends,
      additionals,
      faqs,
      modalShow,
      includes,
      modalShow2,
      question,
      avgrate,
      email,
      phone,
      name,
      loader,
      kitlist,
      eventDateId,
      itineraries,
      up,
      validated1,
      down,
      kits,
      validated,
      up1,
      down1,
      up2,
      down2,
      up3,
      down3,
      up4,
      down4,
      up5,
      down5,
      up6,
      down6,
      up7,
      down7,
      loader2,
      emailFrom,
      emailTo,
    } = this.state;
    let { list } = this.props;
    let Auth = localStorage.getItem("Auth");
    return (
      <div className="bookingdetail" style={{ position: "relative" }}>
        <div
          style={{
            backgroundImage: `url(${list.banner})`,
            backgroundPosition: "center",
            backgroundRepeat: "repeat",
            backgroundSize: "cover",

            opacity: "0.05",
            width: "100%",
            position: "absolute",
            height: "110%",
            zIndex: "1",
          }}
        ></div>

        <div>
          <section>
            <ViewEvent list={list} />
          </section>
          <section className="container section-margin" style={{ zIndex: "5" }}>
            <Row>
              <Col lg={8} style={{ marginBottom: "25px" }}>
                {list.eventImages && list.eventImages.length != 0 && (
                  <Row>
                    <Col lg={12} md={12}>
                      <Carousel
                        controls={false}
                        className="image_border"
                        style={{ height: "438px" }}
                      >
                        {list.eventImages &&
                          list.eventImages.map((item, index) => (
                            <Carousel.Item key={index} className="image_border">
                              <Image
                                src={item.md}
                                className="image_border  "
                                style={{ width: "100%", height: "438px" }}
                              />
                            </Carousel.Item>
                          ))}
                      </Carousel>
                      {/* <div className="favorite">
                    {prevfav && (
                      <FavoriteIcon
                        style={{ color: "grey", cursor: "pointer" }}
                        onClick={() => {
                          this.setState({ fav: true });
                          this.setState({ prevfav: false });
                        }}
                      />
                    )}
                    {fav && (
                      <FavoriteIcon
                        style={{ color: "red" }}
                        onClick={() => {
                          this.setState({ fav: false });
                          this.setState({ prevfav: true });
                        }}
                      />
                    )}
                  </div> */}
                    </Col>
                  </Row>
                )}

                <section style={{ marginTop: "20px" }}>
                  <Row>
                    <Col lg={12}>
                      <h2 style={{ textTransform: "capitalize" }}>
                        {list.title}
                      </h2>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <h3 className="normaltext">
                        <FaCircle className="normaltext icon-style" />
                        Tour Reference ID: {list.tourRef}
                      </h3>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "15px" }}>
                    <Col lg={8}>
                      {list &&
                        list.tags &&
                        list.tags.map((item, index) => (
                          <Badge
                            className="tags"
                            key={index}
                            style={{
                              marginRight: "10px",
                            }}
                          >
                            {item && item.name}
                          </Badge>
                        ))}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6} className="">
                      <Row>
                        <Col lg={12}>
                          <FaCalendar className="normaltext icon-style" />
                          <span className="normaltext icon-style">
                            Duration:{""} {""} {""}{" "}
                          </span>
                          <span className="normaltext">
                            {this.daydiff()} Days
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={12}>
                          <FaMapMarkerAlt className="normaltext icon-style" />
                          <span className="normaltext icon-style">
                            Location:{""} {""} {""}{" "}
                          </span>
                          <span className="normaltext">{list.location}</span>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={12}>
                          <svg
                            // className=""
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            class="bi bi-bezier normaltext icon-style"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M0 10.5A1.5 1.5 0 0 1 1.5 9h1A1.5 1.5 0 0 1 4 10.5v1A1.5 1.5 0 0 1 2.5 13h-1A1.5 1.5 0 0 1 0 11.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm10.5.5A1.5 1.5 0 0 1 13.5 9h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM6 4.5A1.5 1.5 0 0 1 7.5 3h1A1.5 1.5 0 0 1 10 4.5v1A1.5 1.5 0 0 1 8.5 7h-1A1.5 1.5 0 0 1 6 5.5v-1zM7.5 4a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"
                            />
                            <path d="M6 4.5H1.866a1 1 0 1 0 0 1h2.668A6.517 6.517 0 0 0 1.814 9H2.5c.123 0 .244.015.358.043a5.517 5.517 0 0 1 3.185-3.185A1.503 1.503 0 0 1 6 5.5v-1zm3.957 1.358A1.5 1.5 0 0 0 10 5.5v-1h4.134a1 1 0 1 1 0 1h-2.668a6.517 6.517 0 0 1 2.72 3.5H13.5c-.123 0-.243.015-.358.043a5.517 5.517 0 0 0-3.185-3.185z" />
                          </svg>

                          <span className="normaltext icon-style">
                            Difficulty Level:{""} {""} {""}{" "}
                          </span>
                          <span className="normaltext"> {list.difficulty}</span>
                        </Col>
                      </Row>

                      <Row className="">
                        {/* <Col>
                    {list &&
                      list.tags &&
                      list.tags.map((item, index) => (
                      
                          <Badge className="tags"
                           style={{
                          
                            marginLeft: "10px",
                          }}
                          >{item && item.name}</Badge>
                       
                      ))}
                      </Col> */}
                      </Row>
                    </Col>

                    <Col lg={6} md={6} className="">
                      <Row>
                        <Col lg={12}>
                          <FaUserAlt className="normaltext icon-style" />
                          <span className="normaltext icon-style">
                            Age Limit:{""} {""} {""}{" "}
                          </span>
                          <span className="normaltext">
                            {list.ageMin} years to {list.ageMax} years
                          </span>
                        </Col>
                      </Row>

                      {/* <Row>
                      <Col lg={1}>
                        <FaCircle className="normaltext icon-style" />

                      </Col>
                      <Col lg={8}>
                        <h3 className="normaltext">{list.tourRef}</h3>

                      </Col>
                    </Row> */}

                      <Row>
                        <Col lg={12}>
                          <FaOpera className="normaltext icon-style" />
                          <span className="normaltext icon-style">
                            Operator Name:{""} {""} {""}{" "}
                          </span>
                          <span className="normaltext">
                            {list && list.user && list.user.name}
                          </span>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "5px" }}>
                        <Col>
                          <BeautyStars
                            className="star"
                            value={avgrate && avgrate.rating}
                            size="12px"
                            inactiveColor="grey"
                            activeColor="#ffcc00"
                            // value={item && item.rating}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </section>
              </Col>
              <Col lg={4}>
                <div className="position-botton-style">
                  <Card className="Card-style" style={{ height: "438px" }}>
                    {/* <Card.Body className="not_available"><h2>Event Not Available</h2></Card.Body> */}
                    <Card.Body>
                      <Row>
                        <Col>
                          <h2 style={{ textTransform: "capitalize" }}>
                            {list.title}
                          </h2>
                        </Col>
                      </Row>
                      {!this.state.disblebutton && (
                        <Row className="event-row-pd">
                          <Col lg={12}>
                            <p>Select Adventure Date</p>

                            <Input
                              type="select"
                              value={this.state.eventDateId}
                              onChange={(event) => {
                                this.setState({
                                  eventDateId: event.target.value,
                                });
                              }}
                              disabled={this.state.disblebutton}
                            >
                              {eventdatelist &&
                                eventdatelist.map((item, index) => (
                                  <>
                                    {moment().diff(
                                      moment(item && item.startDate),
                                      "hours"
                                    ) <= 0 && (
                                      <option
                                        value={item && item.id}
                                        className=""
                                        key={index}
                                      >
                                        {" "}
                                        {moment(item && item.startDate).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </option>
                                    )}
                                  </>
                                ))}
                            </Input>
                            {/* <h3>
                                {moment(item && item.startDate).format("LL")}
                                
                              </h3> */}
                          </Col>
                        </Row>
                      )}
                      {!this.state.disblebutton && (
                        <Row>
                          <Col lg={12} style={{ textAlign: "center" }}>
                            <p>Amount</p>
                            <h5
                              style={{
                                alignContent: "center",
                                marginTop: 10,
                                marginRight: 10,
                              }}
                              className={
                                list.discount == "0"
                                  ? "discount_price_off"
                                  : "discount_price_off_value  "
                              }
                            >
                              <div
                                className="price-dis"
                                style={{ textDecorationLine: "line-through" }}
                              >
                                {" "}
                                ₹ {list.amount}
                              </div>
                            </h5>
                            <h2 style={{ textAlign: "center" }} className="">
                              ₹{list.amount - this.percen(list)}
                              {/* {list.amount} */}
                            </h2>
                          </Col>
                        </Row>
                      )}
                      {this.state.disblebutton && (
                        <h2 className="justify-content-center align-self-center  no-event">
                          Event Not Available
                        </h2>
                      )}

                      <div
                        className="booknow booknow-width"
                        style={{ paddingBottom: "20px" }}
                      >
                        {!this.state.disblebutton && this.state.seatsavailable && (
                          <Row>
                            <Col lg={12} style={{ marginTop: "15px" }}>
                              <Link
                                to={{
                                  //  pathname: `/bookevent/${this.props.id}`,
                                  state: {
                                    list: list,
                                    eventdatelist: eventdatelist,
                                  },
                                }}
                                style={{ textDecoration: "none" }}
                              >
                                <ButtonCommon
                                  disabled={this.state.disblebutton}
                                  className="all-events-btn1"
                                  label="Book"
                                  handleClick={() => {
                                    let Auth = localStorage.getItem("Auth");
                                    if (
                                      Auth &&
                                      this.props.profile.emailVerified == true
                                    ) {
                                      this.props.history.push(
                                        `/bookevent/${this.props.id}`
                                      );
                                    } else {
                                      this.props.history.push("/login");
                                    }
                                  }}
                                />
                              </Link>
                            </Col>
                          </Row>
                        )}

                        {!this.state.seatsavailable && (
                          <h6 className="justify-content-center align-self-center ">
                            No Seats available!
                          </h6>
                        )}

                        <Row style={{ marginTop: "1rem" }}>
                          <Col>
                            <div style={{ display: "initial" }}>
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.setState({ modalShow2: true });
                                  this.setState({ modalShow: false });
                                }}
                              >
                                <p>
                                  {" "}
                                  <FiMail style={{ marginTop: "-4px" }} /> Email
                                  This Tour
                                </p>
                              </a>
                              {/* <ButtonCommon
                            className="btncommon btnrounded2"
                            label=" Email This Tour"
                            handleClick={() => {
                              this.setState({ modalShow2: true });
                              this.setState({ modalShow: false });
                            }} 
                          />
                          */}
                            </div>
                          </Col>
                          <Col>
                            {list && list.askQuestion && (
                              <div style={{ display: "initial" }}>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    this.setState({ modalShow: true });
                                    this.setState({ modalShow2: false });
                                  }}
                                >
                                  <p>
                                    <FiHelpCircle
                                      style={{ marginTop: "-4px" }}
                                    />
                                    Ask a Question
                                  </p>
                                </a>
                                {/* <ButtonCommon
                            className="btncommon btnrounded2"
                            label="Ask a Question"
                            handleClick={() => {
                              this.setState({ modalShow: true });
                              this.setState({ modalShow2: false });
                            }}
                          /> */}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </div>
                      {/* </div> */}

                      {modalShow2 && (
                        <Modal
                          size="md"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                          show={modalShow2}
                          onHide={() => this.setState({ modalShow2: false })}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                              Email this Tour
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Tabs
                              defaultActiveKey="home"
                              id="uncontrolled-tab-example"
                            >
                              <Tab
                                eventKey="home"
                                title="To Yourself"
                                className="yourself-style"
                              >
                                {loader && <Loader />}
                                {!loader && (
                                  <Form
                                    noValidates
                                    validated={validated1}
                                    onSubmit={this.emailthistour}
                                    style={{ marginTop: "20px" }}
                                  >
                                    <Form.Row>
                                      <Form.Group
                                        as={Col}
                                        md="12"
                                        controlId="validationCustom03"
                                      >
                                        <Form.Control
                                          type="email"
                                          required
                                          placeholder="Your Email"
                                          value={email}
                                          onChange={(event) =>
                                            this.setState({
                                              email: event.target.value,
                                            })
                                          }
                                        />
                                      </Form.Group>
                                      <Col>
                                        <Button
                                          size="sm"
                                          type="submit"
                                          className="btn_small text-center"
                                        >
                                          Send Email
                                        </Button>
                                      </Col>
                                    </Form.Row>
                                    <Row>
                                      <Col></Col>
                                    </Row>
                                  </Form>
                                )}
                              </Tab>
                              <Tab
                                eventKey="friend"
                                title="To a Friend"
                                className="yourself-style"
                              >
                                {loader && <Loader />}
                                {!loader && (
                                  <Form
                                    noValidates
                                    validated={validated1}
                                    onSubmit={this.sendEmail}
                                    style={{ marginTop: "20px" }}
                                  >
                                    <Form.Row>
                                      <Form.Group
                                        as={Col}
                                        md="12"
                                        controlId="validationCustom03"
                                      >
                                        <Form.Control
                                          type="text"
                                          placeholder="Your Name"
                                          required
                                          value={name}
                                          onChange={(event) =>
                                            this.setState({
                                              name: event.target.value,
                                            })
                                          }
                                        />
                                      </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                      <Form.Group
                                        as={Col}
                                        md="12"
                                        controlId="validationCustom03"
                                      >
                                        <Form.Control
                                          type="text"
                                          placeholder="Your Email"
                                          required
                                          value={emailFrom}
                                          onChange={(event) =>
                                            this.setState({
                                              emailFrom: event.target.value,
                                            })
                                          }
                                        />
                                      </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                      <Form.Group
                                        as={Col}
                                        md="12"
                                        controlId="validationCustom03"
                                      >
                                        <Form.Control
                                          type="text"
                                          placeholder="Your Friends Email"
                                          required
                                          value={emailTo}
                                          onChange={(event) =>
                                            this.setState({
                                              emailTo: event.target.value,
                                            })
                                          }
                                        />
                                      </Form.Group>
                                      <Col>
                                        <Button
                                          size="sm"
                                          type="submit"
                                          className="btn_small text-center"
                                          type="submit"
                                        >
                                          Send Email
                                        </Button>
                                      </Col>
                                    </Form.Row>
                                  </Form>
                                )}
                              </Tab>
                            </Tabs>
                          </Modal.Body>
                        </Modal>
                      )}

                      {modalShow && (
                        <Modal
                          size="md"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                          show={modalShow}
                          onHide={() => this.setState({ modalShow: false })}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                              Ask a Question
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <h4>
                              {" "}
                              Feel free to ask us anything about this tour. A
                              Travel Expert will then get back to you as soon as
                              possible.
                            </h4>
                            {!loader2 && (
                              <Form
                                noValidate
                                validated={validated}
                                onSubmit={this.askQue}
                              >
                                <Row>
                                  <Col lg="12">
                                    <Form.Group controlId="validationCustom01">
                                      <Label for="exampleEmail">Name</Label>
                                      <Form.Control
                                        required
                                        type="text"
                                        placeholder="Name"
                                        className="inputField"
                                        value={name}
                                        onChange={(e) =>
                                          this.setState({
                                            name: e.target.value,
                                          })
                                        }
                                      />
                                    </Form.Group>
                                    <Form.Group controlId="validationCustom02">
                                      <Label for="exampleEmail">Email</Label>
                                      <Form.Control
                                        required
                                        type="email"
                                        placeholder="Email"
                                        className="inputField"
                                        value={email}
                                        onChange={(e) =>
                                          this.setState({
                                            email: e.target.value,
                                          })
                                        }
                                      />
                                    </Form.Group>
                                    <Form.Group controlId="validationCustom03">
                                      <Label for="exampleEmail">Mobile</Label>
                                      <Form.Control
                                        required
                                        type="number"
                                        placeholder="Mobile"
                                        className="inputField"
                                        value={phone}
                                        onChange={(e) =>
                                          this.setState({
                                            phone: e.target.value,
                                          })
                                        }
                                      />
                                    </Form.Group>

                                    <Form.Group
                                      md="12"
                                      controlId="validationCustom03"
                                    >
                                      <Form.Label>Description</Form.Label>
                                      <Form.Control
                                        type="text"
                                        as="textarea"
                                        required
                                        rows={3}
                                        placeholder="Enter your Question"
                                        value={question}
                                        onChange={(event) =>
                                          this.setState({
                                            question: event.target.value,
                                          })
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Button
                                  type="submit"
                                  style={{ width: "100%" }}
                                  className="all-events-btn text-center"
                                >
                                  Send Question
                                </Button>
                              </Form>
                            )}
                            {loader2 && <Loader />}
                          </Modal.Body>
                        </Modal>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Row className="Margin-20">
                  <Col lg={12}>
                    <Card className="Card-style Card-style2">
                      <Card.Body>
                        <Row>
                          <Col>
                            <h2>Overview</h2>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p style={{ whiteSpace: "pre-wrap" }}>
                              {list.description}
                            </p>
                          </Col>
                        </Row>

                        {list.meetingLocation != null && (
                          <Row>
                            <Col lg={6} className="meetingLocation">
                              <span>
                                <FaHandshake className="normaltext icon-style" />
                              </span>
                              <h3>
                                Meeting Point:{""} {""} {""}{" "}
                                {list.meetingLocation}
                              </h3>
                            </Col>
                          </Row>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <section>
                  <div style={{ paddingTop: "30px" }}>
                    <Row>
                      <Col lg={12}>
                        <Accordion
                          // defaultActiveKey="0"
                          className="h2-text-event-details"
                        >
                          <Card className="toogle-card">
                            <Accordion.Toggle
                              className="Card-style card-headers accCard"
                              as={Card.Header}
                              eventKey="0"
                            >
                              <Row onClick={() => this.getincludes()}>
                                <Col lg={11}>
                                  <h2>Whats Included/Excluded</h2>
                                  <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                      {!this.state.includescalled && (
                                        <Spinner
                                          className=""
                                          animation="border"
                                          variant="dark"
                                          role="status"
                                          aria-hidden="true"
                                        ></Spinner>
                                      )}
                                      {includes &&
                                        includes.map((item) => {
                                          return (
                                            <div>
                                              <h3
                                                style={{
                                                  textTransform: "capitalize",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {item.included === true && (
                                                  <FaCheck
                                                    style={{
                                                      marginRight: "10px",
                                                      // color: "green",
                                                    }}
                                                  />
                                                )}
                                                {item.included === false && (
                                                  <FaTimes
                                                    style={{
                                                      marginRight: "10px",
                                                      // color: "red",
                                                    }}
                                                  />
                                                )}
                                                {item.title}
                                              </h3>
                                              <p>{item.description}</p>
                                            </div>
                                          );
                                        })}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Col>
                                <div className="chevronn">
                                  {down && (
                                    <FaChevronDown
                                      className="chevron"
                                      onClick={() => {
                                        this.setState({ up: true });
                                        this.setState({ down: false });
                                      }}
                                    />
                                  )}
                                  {up && (
                                    <FaChevronUp
                                      className="chevron"
                                      onClick={() => {
                                        this.setState({ up: false });
                                        this.setState({ down: true });
                                      }}
                                    />
                                  )}
                                </div>
                                {/* <Col>
                               
                              </Col> */}
                              </Row>
                            </Accordion.Toggle>
                          </Card>

                          <Card className="toogle-card">
                            <Accordion.Toggle
                              className="Card-style card-headers accCard"
                              as={Card.Header}
                              eventKey="1"
                            >
                              <Row onClick={() => this.getitineraries()}>
                                <Col lg={11}>
                                  <h2>Itinerary</h2>
                                  <Accordion.Collapse eventKey="1">
                                    <>
                                      {!this.state.itinerariescalled && (
                                        <Spinner
                                          className=""
                                          animation="border"
                                          variant="dark"
                                          role="status"
                                          aria-hidden="true"
                                        ></Spinner>
                                      )}
                                      {itineraries &&
                                        itineraries.map((item) => {
                                          return (
                                            <>
                                              <Card.Body>
                                                <h3 className="details-font1">
                                                  Day {""}
                                                  {item.day}
                                                </h3>
                                                <h4>{item.title}</h4>
                                                <p>{item.description}</p>

                                                {item.location !== "" && (
                                                  <div>
                                                    <h5>
                                                      <BsGeoAlt
                                                        style={{
                                                          fontSize: "18px",
                                                          // color: "green"
                                                        }}
                                                      />{" "}
                                                      {""}
                                                      {item.location}
                                                    </h5>
                                                  </div>
                                                )}

                                                {item.activities != null && (
                                                  <div>
                                                    <h5>
                                                      <MdLocalActivity
                                                        style={{
                                                          fontSize: "18px",
                                                          // color: "green"
                                                        }}
                                                      />
                                                      {""} {item.activities}
                                                    </h5>
                                                  </div>
                                                )}

                                                {/* <h5>
                                              <BiTime />
                                              {""} {item.timeHours}
                                            </h5> 
                                            */}
                                                <Row>
                                                  <Col lg={6}>
                                                    <div>
                                                      <Row>
                                                        <Col>
                                                          {item.food != "" && (
                                                            <h4>
                                                              <GiFoodTruck
                                                                style={
                                                                  {
                                                                    // fontSize: "18px",
                                                                    //  color: "green"
                                                                  }
                                                                }
                                                              />{" "}
                                                              {""}
                                                              {item.food}
                                                            </h4>
                                                          )}
                                                        </Col>
                                                      </Row>
                                                      <Row>
                                                        <Col>
                                                          {item.foodImage !=
                                                            null && (
                                                            <img
                                                              className="image_border"
                                                              src={
                                                                item.foodImage
                                                              }
                                                              style={{
                                                                marginBottom:
                                                                  "20px",
                                                                width: "200px",
                                                                height: "200px",
                                                              }}
                                                            />
                                                          )}
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  </Col>
                                                  <Col lg={6}>
                                                    <div>
                                                      <Row>
                                                        <Col>
                                                          {item.accommodation !=
                                                            "" && (
                                                            <h4>
                                                              <FaHome
                                                                style={
                                                                  {
                                                                    // fontSize: "18px",
                                                                    //  color: "green"
                                                                  }
                                                                }
                                                              />{" "}
                                                              {""}
                                                              {
                                                                item.accommodation
                                                              }
                                                            </h4>
                                                          )}
                                                        </Col>
                                                      </Row>
                                                      <Row>
                                                        <Col>
                                                          {item.accImage !=
                                                            null && (
                                                            <img
                                                              className="image_border"
                                                              src={
                                                                item.accImage
                                                              }
                                                              style={{
                                                                marginBottom:
                                                                  "20px",
                                                                width: "200px",
                                                                height: "200px",
                                                              }}
                                                            />
                                                          )}
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </Card.Body>
                                            </>
                                          );
                                        })}
                                    </>
                                  </Accordion.Collapse>
                                </Col>
                                <div className="chevronn">
                                  {down2 && (
                                    <FaChevronDown
                                      className="chevron"
                                      onClick={() => {
                                        this.setState({ up2: true });
                                        this.setState({ down2: false });
                                      }}
                                    />
                                  )}
                                  {up2 && (
                                    <FaChevronUp
                                      className="chevron"
                                      onClick={() => {
                                        this.setState({ up2: false });
                                        this.setState({ down2: true });
                                      }}
                                    />
                                  )}
                                </div>
                                {/* <Col className="">
                               
                              </Col> */}
                              </Row>
                            </Accordion.Toggle>
                          </Card>

                          <Card className="toogle-card">
                            <Accordion.Toggle
                              className="Card-style card-headers accCard"
                              as={Card.Header}
                              eventKey="2"
                            >
                              <Row>
                                <Col lg={11} onClick={() => this.getKitlist()}>
                                  <h2>Kit</h2>
                                  <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                      <Row>
                                        <Col lg={6}>
                                          <h3 className="details-font1">
                                            Included
                                          </h3>
                                          {!this.state.kitlistcalled && (
                                            <Spinner
                                              className=""
                                              animation="border"
                                              variant="dark"
                                              role="status"
                                              aria-hidden="true"
                                            ></Spinner>
                                          )}
                                          {kitlist &&
                                            kitlist.map((item) => (
                                              <>
                                                {item.included === true && (
                                                  <div>
                                                    <p
                                                      style={{
                                                        marginBottom: "0px",
                                                      }}
                                                    >
                                                      <FaCheck
                                                        className="kit"
                                                        style={{
                                                          marginRight: "10px",
                                                          // color: "green",
                                                        }}
                                                      />
                                                      {item.description}
                                                    </p>
                                                  </div>

                                                  // </Col>
                                                )}
                                              </>
                                            ))}
                                        </Col>

                                        <Col lg={6}>
                                          <h3 className="details-font1">
                                            Excluded
                                          </h3>
                                          {kitlist &&
                                            kitlist.map((item) => (
                                              <>
                                                {item.included === false && (
                                                  <div>
                                                    <p
                                                      style={{
                                                        marginBottom: "0px",
                                                      }}
                                                    >
                                                      <FaTimes
                                                        className="kit"
                                                        style={{
                                                          marginRight: "10px",
                                                          // color: "red",
                                                        }}
                                                      />
                                                      {item.description}
                                                    </p>
                                                  </div>
                                                )}
                                              </>
                                            ))}
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Col>
                                <div className="chevronn">
                                  {down3 && (
                                    <FaChevronDown
                                      className="chevron"
                                      onClick={() => {
                                        this.setState({ up3: true });
                                        this.setState({ down3: false });
                                      }}
                                    />
                                  )}
                                  {up3 && (
                                    <FaChevronUp
                                      className="chevron"
                                      onClick={() => {
                                        this.setState({ up3: false });
                                        this.setState({ down3: true });
                                      }}
                                    />
                                  )}
                                </div>
                                {/* <Col className="">
                                
                              </Col> */}
                              </Row>
                            </Accordion.Toggle>
                          </Card>

                          <Card className="toogle-card">
                            <Accordion.Toggle
                              className="Card-style card-headers accCard"
                              as={Card.Header}
                              eventKey="3"
                            >
                              <Row onClick={() => this.getrecommends()}>
                                <Col lg={11}>
                                  <h2>Recommended For</h2>
                                  <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                      {!this.state.recommendscalled && (
                                        <Spinner
                                          className=""
                                          animation="border"
                                          variant="dark"
                                          role="status"
                                          aria-hidden="true"
                                        ></Spinner>
                                      )}
                                      <div className="event-font-size">
                                        <Row>
                                          {recommends &&
                                            recommends.couple === true && (
                                              <Col lg={2}>
                                                <div>
                                                  <h4>
                                                    <span>
                                                      <ImManWoman className="icon-style" />
                                                    </span>{" "}
                                                    Couple
                                                  </h4>
                                                </div>
                                              </Col>
                                            )}

                                          {list &&
                                            recommends &&
                                            recommends.family === true && (
                                              <Col lg={2}>
                                                <div>
                                                  <h4>
                                                    <span>
                                                      <TiGroup className="icon-style" />
                                                    </span>{" "}
                                                    Family
                                                  </h4>
                                                </div>
                                              </Col>
                                            )}

                                          {list &&
                                            recommends &&
                                            recommends.friends === true && (
                                              <Col lg={2}>
                                                <div>
                                                  <h4>
                                                    <span>
                                                      <FaUserFriends className="icon-style" />
                                                    </span>
                                                    Friends
                                                  </h4>
                                                </div>
                                              </Col>
                                            )}
                                          {list &&
                                            recommends &&
                                            recommends.kids === true && (
                                              <Col lg={2}>
                                                <div>
                                                  <h4>
                                                    <span>
                                                      <FaChild className="icon-style" />
                                                    </span>
                                                    Kids
                                                  </h4>
                                                </div>
                                              </Col>
                                            )}
                                          {list &&
                                            recommends &&
                                            recommends.lgbtq === true && (
                                              <Col lg={2}>
                                                <div>
                                                  <h4>
                                                    <span>
                                                      <FaUserFriends className="icon-style" />
                                                    </span>
                                                    LGBTQ
                                                  </h4>
                                                </div>
                                              </Col>
                                            )}
                                          {recommends &&
                                            recommends.parents === true && (
                                              <Col lg={2}>
                                                <div>
                                                  <h4>
                                                    <span>
                                                      <RiGroupLine className="icon-style" />
                                                    </span>
                                                    Parents
                                                  </h4>
                                                </div>
                                              </Col>
                                            )}
                                          {recommends &&
                                            recommends.senior === true && (
                                              <Col lg={2}>
                                                <div>
                                                  <h4>
                                                    <span>
                                                      <IoIosMan className="icon-style" />
                                                    </span>
                                                    Senior
                                                  </h4>
                                                </div>
                                              </Col>
                                            )}
                                          {recommends &&
                                            recommends.solo === true && (
                                              <Col lg={2}>
                                                <div>
                                                  <h4>
                                                    <span>
                                                      <GoPerson className="icon-style" />
                                                    </span>
                                                    Solo
                                                  </h4>
                                                </div>
                                              </Col>
                                            )}
                                          {list &&
                                            recommends &&
                                            recommends.youth === true && (
                                              <Col lg={2}>
                                                <div>
                                                  <h4>
                                                    <span>
                                                      <TiGroup className="icon-style" />
                                                    </span>
                                                    Youth
                                                  </h4>
                                                </div>
                                              </Col>
                                            )}
                                        </Row>
                                      </div>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Col>
                                <div className="chevronn">
                                  {down4 && (
                                    <FaChevronDown
                                      className="chevron"
                                      onClick={() => {
                                        this.setState({ up4: true });
                                        this.setState({ down4: false });
                                      }}
                                    />
                                  )}
                                  {up4 && (
                                    <FaChevronUp
                                      className="chevron"
                                      onClick={() => {
                                        this.setState({ up4: false });
                                        this.setState({ down4: true });
                                      }}
                                    />
                                  )}
                                </div>
                                {/* <Col className="">
                               
                              </Col> */}
                              </Row>
                            </Accordion.Toggle>
                          </Card>

                          <Card className="toogle-card">
                            <Accordion.Toggle
                              className="Card-style card-headers accCard"
                              as={Card.Header}
                              eventKey="4"
                            >
                              <Row onClick={() => this.getadditionals()}>
                                <Col lg={11}>
                                  <h2>Good To Know</h2>
                                  <Accordion.Collapse eventKey="4">
                                    <>
                                      {!this.state.additionalscalled && (
                                        <Spinner
                                          className=""
                                          animation="border"
                                          variant="dark"
                                          role="status"
                                          aria-hidden="true"
                                        ></Spinner>
                                      )}
                                      {additionals &&
                                        additionals.map((item) => (
                                          <>
                                            <Card.Body
                                              style={{
                                                paddingTop: "10px",
                                                paddingBottom: "15px",
                                              }}
                                            >
                                              <h3
                                                className="details-font1"
                                                style={{ fontWeight: "500" }}
                                              >
                                                {item.title}
                                              </h3>
                                              <p
                                                style={{ marginBottom: "0px" }}
                                              >
                                                {item.description}
                                              </p>
                                            </Card.Body>
                                          </>
                                        ))}
                                    </>
                                  </Accordion.Collapse>
                                </Col>
                                <div className="chevronn">
                                  {down5 && (
                                    <FaChevronDown
                                      className="chevron"
                                      onClick={() => {
                                        this.setState({ up5: true });
                                        this.setState({ down5: false });
                                      }}
                                    />
                                  )}
                                  {up5 && (
                                    <FaChevronUp
                                      className="chevron"
                                      onClick={() => {
                                        this.setState({ up5: false });
                                        this.setState({ down5: true });
                                      }}
                                    />
                                  )}
                                </div>
                                {/* <Col className="">
                                
                              </Col> */}
                              </Row>
                            </Accordion.Toggle>
                          </Card>
                          <Card className="toogle-card">
                            <Accordion.Toggle
                              className="Card-style card-headers accCard"
                              as={Card.Header}
                              eventKey="5"
                            >
                              <Row>
                                <Col lg={11}>
                                  <h2>
                                    Customer Review
                                    <Link
                                      to={{
                                        pathname: "/allreviews",
                                        ratinglist: rating,
                                        // ...item,
                                        // index: index,
                                      }}
                                      style={{ textDecoration: "none" }}
                                    >
                                      {rating && rating.length != 0 && (
                                        <h3 className="customer-review-btn see_all_link">
                                          See All
                                        </h3>
                                      )}
                                      {/* <div className="customer-review-btn">
                                        <ButtonCommon
                                          className="all-events-btn"
                                          label=" See All"
                                        />
                                      </div> */}
                                    </Link>
                                  </h2>
                                  <Accordion.Collapse eventKey="5">
                                    <Card.Body style={{ marginBottom: "25px" }}>
                                      {/* <Link
                                      to={{
                                        pathname: "/allreviews",
                                        ratinglist: rating,
                                        
                                      }}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <div className="customer-review-btn">
                                        <ButtonCommon
                                          className="all-events-btn"
                                          label=" See All"
                                        />
                                      </div>
                                    </Link> */}
                                      {rating && rating.length == 0 && (
                                        <label>No Reviews!</label>
                                      )}
                                      {rating.map((item, index) => (
                                        <>
                                          <Row>
                                            <Col
                                              lg={2}
                                              md={2}
                                              style={{ margin: "auto" }}
                                            >
                                              <img
                                                src={
                                                  item &&
                                                  item.user &&
                                                  item.user.profileImage &&
                                                  item.user.profileImage
                                                    .original
                                                }
                                                style={{
                                                  width: "60px",
                                                  height: "60px",
                                                  margin: "auto",
                                                  borderRadius: "50px",
                                                }}
                                              />
                                            </Col>
                                            <Col lg={9} md={9}>
                                              <h3 style={{ fontWeight: "500" }}>
                                                {item.name}
                                              </h3>
                                              <p
                                                style={{ marginBottom: "15px" }}
                                              >
                                                {item.description}
                                              </p>

                                              <BeautyStars
                                                // value={item.rating}
                                                size="16px"
                                                inactiveColor="grey"
                                                activeColor="#fdd72a"
                                                value={item && item.rating}
                                              />
                                            </Col>
                                          </Row>
                                          <hr />
                                        </>
                                      ))}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Col>
                                <div className="chevronn">
                                  {down6 && (
                                    <FaChevronDown
                                      className="chevron"
                                      onClick={() => {
                                        this.setState({ up6: true });
                                        this.setState({ down6: false });
                                      }}
                                    />
                                  )}
                                  {up6 && (
                                    <FaChevronUp
                                      className="chevron"
                                      onClick={() => {
                                        this.setState({ up6: false });
                                        this.setState({ down6: true });
                                      }}
                                    />
                                  )}
                                </div>
                                {/* <Col className="">
                                
                              </Col> */}
                              </Row>
                            </Accordion.Toggle>
                          </Card>

                          <Card className="toogle-card">
                            <Accordion.Toggle
                              className="Card-style card-headers accCard"
                              as={Card.Header}
                              eventKey="6"
                            >
                              <Row onClick={() => this.getfaqs()}>
                                <Col lg={11}>
                                  <h2>FAQ</h2>
                                  <Accordion.Collapse eventKey="6">
                                    <>
                                      {!this.state.faqscalled && (
                                        <Spinner
                                          className=""
                                          animation="border"
                                          variant="dark"
                                          role="status"
                                          aria-hidden="true"
                                        ></Spinner>
                                      )}
                                      {faqs &&
                                        faqs.map((item, index) => (
                                          <>
                                            <Card.Body
                                              style={{
                                                paddingTop: "10px",
                                                paddingBottom: "15px",
                                              }}
                                            >
                                              <h3
                                                className="details-font1"
                                                style={{ fontWeight: "500" }}
                                              >
                                                {item && item.question}
                                              </h3>
                                              <p
                                                style={{ marginBottom: "0px" }}
                                              >
                                                {item && item.answer}
                                              </p>
                                            </Card.Body>
                                          </>
                                        ))}
                                    </>
                                  </Accordion.Collapse>
                                </Col>
                                <div className="chevronn">
                                  {down7 && (
                                    <FaChevronDown
                                      className="chevron faq"
                                      onClick={() => {
                                        this.setState({ up7: true });
                                        this.setState({ down7: false });
                                      }}
                                    />
                                  )}
                                  {up7 && (
                                    <FaChevronUp
                                      className="chevron faq"
                                      onClick={() => {
                                        this.setState({ up7: false });
                                        this.setState({ down7: true });
                                      }}
                                    />
                                  )}
                                </div>
                                {/* <Row> */}
                                {/* <Col lg={1} className="">
                               
                              </Col> */}
                                {/* </Row> */}
                              </Row>
                            </Accordion.Toggle>
                          </Card>
                        </Accordion>
                      </Col>
                    </Row>
                    {/* <Row style={{ marginTop: "20px" }} id="upcoming">
              <Col lg={12} className="Card-style card-headers">

                <h2 className="text-event-details">Upcoming departures</h2> */}
                    <div className="section-margin">
                      {list && list.category && (
                        <SimilarAdventures
                          history={this.props && this.props.history}
                          category={list.category}
                        />
                      )}
                    </div>
                  </div>
                </section>
              </Col>
            </Row>
          </section>
        </div>
      </div>
    );
  }
}

//export default EventDetailsCard;

const mapStateToProps = (state) => ({
  profile: state.homeReducer.profile,
});

export default connect(mapStateToProps, null)(EventDetailsCard);
