
import React, { useState,Component } from 'react';
import { Form,Row,Col,Badge , Container,
     Carousel,Accordion,Card,Button,Label,Modal,
    Image} from 'react-bootstrap';
import BeautyStars from 'beauty-stars';
import {
  FaCheck,
  FaCalendar,
  FaMapMarkerAlt,
  FaUsers,
  FaMapMarkedAlt,
  FaUserAlt,    FaCircle,
    FaChevronDown,
    FaChevronUp,
  } from "react-icons/fa";
import Api from "../Service/Api";
import { FaOpera } from "react-icons/fa";
import Popup from 'reactjs-popup';
import moment from "moment";

import ButtonCommon from "./ButtonCommon";
import Loaders from "./FullScreenLoader";


class UserDetailsCancellation extends Component {
    constructor(props) {
      super(props);
      this.state = {
      list:[],
      banner:'',
      endDate:'',
      startDate:'',
       limit: 10,
      skip: 0,
      eventid:'',
      value:0,
      popup:false,
      close:false,
      loader:true,
       modalShow2: false,
    
    }
  }

  daydiff = (list) => {
    var a = moment(list && list.endDate);
    var b = moment(list && list.startDate);
    // return a.diff(b, "days");
    var c = a.diff(b, "days") + 1;
    return c;
  };
  cancelbooking = (v)=>{
    this.setState({ modalShow2: false,loading: true })
    let accesstoken = localStorage.getItem("accesstoken");
    let Auth = localStorage.getItem("Auth");
    const token = `Bearer ${accesstoken}`;
    Api.patch(`/bookings/${v}/cancelBooking`, {},{
      headers: {
        
        Authorization: token,
      },
    }).then((res) => {
   
       window.location.reload();
       this.setState({loader:false})
    }).catch(err=>{
       
    })
  }


  render() {
    const { image, place,} = this.props;
    let {banner,endDate,startDate, popup, avgrate, loader, modalShow2} =this.state
    let {list,book}=this.props;
    

  return (
      <div>
      
            

                  <section className="Card-style carousel-height2 user_cancellation" >
                <Row>
                  <Col lg={12}>
                    <h2 style={{ textTransform: 'capitalize' }}>
                      {list.title}
                    </h2>
                  </Col>
                </Row>
                <Row style={{ marginBottom: '15px' }}>
                  <Col lg={8}>
                    {list &&
                      list.tags &&
                      list.tags.map((item, index) => (

                        <Badge className="tags"
                          style={{

                            marginRight: "10px",
                          }}
                        >{item && item.name}</Badge>

                      ))}
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
             <Row>
                        <Col lg={12}>
                          <FaCalendar className="normaltext icon-style" />
                          <span className="normaltext icon-style">
                            Duration:{""} {""} {""}{" "}
                          </span>
                          <span className="normaltext">
                            {this.daydiff()} Days
                          </span>
                        </Col>
                      </Row>

                    <Row>
                        <Col lg={12}>
                          <FaMapMarkerAlt className="normaltext icon-style" />
                          <span className="normaltext icon-style">
                            Meeting Point:{""} {""} {""}{" "}
                          </span>
                          <span className="normaltext">{list.location}</span>
                        </Col>
                      </Row>
                    <Row>
                        <Col lg={12}>
                          <svg
                            // className=""
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            class="bi bi-bezier normaltext icon-style"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M0 10.5A1.5 1.5 0 0 1 1.5 9h1A1.5 1.5 0 0 1 4 10.5v1A1.5 1.5 0 0 1 2.5 13h-1A1.5 1.5 0 0 1 0 11.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm10.5.5A1.5 1.5 0 0 1 13.5 9h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM6 4.5A1.5 1.5 0 0 1 7.5 3h1A1.5 1.5 0 0 1 10 4.5v1A1.5 1.5 0 0 1 8.5 7h-1A1.5 1.5 0 0 1 6 5.5v-1zM7.5 4a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"
                            />
                            <path d="M6 4.5H1.866a1 1 0 1 0 0 1h2.668A6.517 6.517 0 0 0 1.814 9H2.5c.123 0 .244.015.358.043a5.517 5.517 0 0 1 3.185-3.185A1.503 1.503 0 0 1 6 5.5v-1zm3.957 1.358A1.5 1.5 0 0 0 10 5.5v-1h4.134a1 1 0 1 1 0 1h-2.668a6.517 6.517 0 0 1 2.72 3.5H13.5c-.123 0-.243.015-.358.043a5.517 5.517 0 0 0-3.185-3.185z" />
                          </svg>

                          <span className="normaltext icon-style">
                            Difficulty Level:{""} {""} {""}{" "}
                          </span>
                          <span className="normaltext"> {list.difficulty}</span>
                        </Col>
                      </Row>

                      
                   
                  </Col>

                  <Col lg={6}>
                    <Row>
                        <Col lg={12}>
                          <FaUserAlt className="normaltext icon-style" />
                          <span className="normaltext icon-style">
                            Age Limit:{""} {""} {""}{" "}
                          </span>
                          <span className="normaltext">
                            {list.ageMin} years to {list.ageMax} years
                          </span>
                        </Col>
                      </Row>

                    {/*<Row>
                      <Col lg={1}>
                        <FaCircle className="normaltext icon-style" />

                      </Col>
                      <Col lg={8}>
                        <h3 className="normaltext">{list.tourRef}</h3>

                      </Col>
                    </Row>*/}

                  <Row>
                        <Col lg={12}>
                          <FaOpera className="normaltext icon-style" />
                          <span className="normaltext icon-style">
                            Operator Name:{""} {""} {""}{" "}
                          </span>
                          <span className="normaltext">
                            {list && list.user && list.user.name}
                          </span>
                        </Col>
                      </Row>
                    <Row style={{marginTop:'5px'}}>
                      <Col>
                        <BeautyStars
                          className="star"
                          value={avgrate && avgrate.rating}
                          size="12px"
                          inactiveColor="grey"
                          activeColor="#ffcc00"
                        // value={item && item.rating}
                        />

                      </Col>
                    </Row>
                   
                


                  </Col>
                  
                </Row>
               
                <Row className="text-center" style={{display:'block', marginTop:'20px'}}>
               {book&&book.payment&& book.payment.status == "successful" &&
               <ButtonCommon
                  label="Cancel"
                  className="all-events-btn text-center"
                  //  handleClick={ ()=>{this.cancelbooking(book&&book.payment&&book.payment.bookingId)}}
                  handleClick={()=>this.setState({modalShow2: true})}
                />   }
              </Row>

            <Modal
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={modalShow2}
              onHide={() => this.setState({ modalShow2: false })}
            >
              <Modal.Body>
                <Row className="mb-4 text-center">
                  <Col>
                    <h2>Are you sure you want to cancel the booking?</h2>
                  </Col>
                </Row>
                <Row style={{margin:'auto', textAlign:'center'}}>
                  <Col>
                    <ButtonCommon 
                    handleClick={ ()=>{this.cancelbooking(book&&book.payment&&book.payment.bookingId)}}
                    className=" btn_small  margin-userbooking" label="Yes" />
                  {/* </Col>
                  <Col> */}
                    <ButtonCommon
                      className=" btn_small"
                      label="No"
                      handleClick={() => this.setState({ modalShow2: false })}
                    />
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
              </section>

    
                      
            
      </div>
       );
    };
    }
    
    export default UserDetailsCancellation;
    