import React, { useState, useEffect } from "react";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Footer from "../Components/Footer";
import VieweventDetails from "../Components/VieweventDetails";
import Api from "../Service/Api";
import EventDetailsCard from "../Components/EventDetailsCard";
import Loaders from "../Components/FullScreenLoader";

const ViewSingleEvent = (props) => {
  const [list, setList] = useState("");
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    geteventlist();
  }, []);
  const geteventlist = () => {
    Api.get(
      `/events/${props.match.params.id}?&relations=["eventImages","tags","user"]`
    )
      .then((res) => {
        setList(res.data);
        setLoader(false);
      })
      .catch((err) => {});
  };

  return (
    <div style={{ position: "relative" }}>
      {/* <section className="search-section">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <VieweventDetails
              history={props.history}
              id={props.match.params.id}
              list={list}
            />
          </MuiPickersUtilsProvider>
        </section> */}
      {loader && <Loaders />}
      <EventDetailsCard
        history={props.history}
        id={props.match.params.id}
        list={list}
      />

      <Footer />
    </div>
  );
};

export default ViewSingleEvent;
