import React, { useState, Component } from "react";
import {
  Form,
  Row,
  Col,
  Badge,
  Container,
  Carousel,
  Accordion,
  Card,
  Button,
  Image,
  Modal,
} from "react-bootstrap";
import { Label } from "reactstrap";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core";
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { ThemeProvider } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import BeautyStars from "beauty-stars";
import FavoriteIcon from "@material-ui/icons/Favorite";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import ButtonBase from "@material-ui/core/ButtonBase";
import DestinationImage from "../../static/Images/destination1.jpg";
//  import Carousel from 'react-material-ui-carousel';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaCheck } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { GiFoodTruck } from "react-icons/gi";
import { FaHome } from "react-icons/fa";
import { FaOpera } from "react-icons/fa";
import { BsGeoAlt } from "react-icons/bs";
import { FiMail,FiHelpCircle} from "react-icons/fi";
import { MdLocalActivity } from "react-icons/md";
import { BiTime } from "react-icons/bi";
import Api from "../Service/Api";
import moment from "moment";
import Loader from "./Loader";
import Loaders from "./FullScreenLoader";

class OperatorQes extends Component {
  constructor(props) {
    super(props);
    // console.log("operator que props", props.opid);
    this.state = {
      list: [],
      banner: "",
      endDate: "",
      startDate: "",
      limit: 10,
      skip: 0,
      eventid: "",
      value: 0,
      eventdatelist: [],
      id: "",
      rating: [],
      faq: [],
      fav: false,
      prevfav: true,
      modalShow: false,
      validated: false,
      email: "",
      phone: "",
      question: "",
      opid: props.opid,
     
    };
  }

  componentDidMount() {
    // this.getAllEventDate();
    // this.getratinglist();
  }

  getratinglist = () => {
    // console.log("res view single event");
    let accesstoken = localStorage.getItem("accesstoken");
    const token = `Bearer ${accesstoken}`;
    Api.get(`/events/${this.props.id}/reviews?`, {
      headers: {
        "content-type": "application/json",
        Authorization: token,
      },
    })
      .then((res) => {
        // console.log("rating response", res);

        this.setState({ rating: res.data });
        // console.log("rating", this.state.rating);
      })
      .catch((err) => {});
  };

  getAllEventDate = () => {
    //  let {list}=this.props;
    // let {id} = this.props.list.id;
    // console.log("id",list.id);
    // this.setState({ active: true });
    let accesstoken = localStorage.getItem("accesstoken");
    const token = `Bearer ${accesstoken}`;
    Api.get(
      `/events/${this.props.id}/event-dates?relations=["bmtEvent","availability"]`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => {
        //   console.log(res);
        this.setState({ eventdatelist: res.data });

        //  console.log("eventdatelist", this.state.eventdatelist[0].startDate);
        // console.log("eventdatelist", this.state.eventdatelist);
      })
      .catch((err) => {
        // this.setState({
        //   active: false,
        // });
      });
  };

  askQue = (event) => {
    // console.log("this.state.opid", this.state.opid);
    let accesstoken = localStorage.getItem("accesstoken");
    const token = `Bearer ${accesstoken}`;
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ validated: true });
    if (form.checkValidity() === true) {
      event.preventDefault();
      this.setState({ loader2: true });
      let data = {
        question: this.state.question,
        name: this.state.name,
        phone: this.state.phone,
        email: this.state.email,
      };
      Api.post(`/operators/${this.state.opid}/operator-queries`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
        //   console.log(res);
          this.setState({
            modalShow: false,
            loader2: false,
            question: "",
            name: "",
            phone: "",
            email: "",
          });
          alert(
              "Thank you for showing interest in BookMyTrek!. A Travel Expert will get back to you as soon as possible and update status by mail"
            );
        })
        .catch((err) => {
          // console.log(err.response);
        });
    }
  };

  daydiff = () => {
    const { list } = this.props;
    // console.log("daydiff-list",list)
    var a = moment(list && list.endDate);
    var b = moment(list && list.startDate);
    return a.diff(b, "days");
  };

  render() {
    const { image, place } = this.props;
    let {
      banner,
      endDate,
      startDate,
      eventdatelist,
      rating,
      fav,
      prevfav,
      modalShow,
      Loader,
      validated,
      loader,
      loader2,
      name,
      email,
      phone,
      question,
    } = this.state;
    let { list } = this.props;

    //  console.log("list", list);

    return (
      <div>
        <Row style={{ marginTop: "20px", float: "right" }}>
          <a
            style={{ textDecoration: "none" ,cursor: "pointer"}}
            className=""
            onClick={() => {
              this.setState({ modalShow: true });
            }}
          >
          
             <p><FiHelpCircle style={{ marginTop: '-4px' ,cursor: "pointer"}} />Ask a Question</p>
          </a>

          {modalShow && (
            <Modal
            size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={modalShow}
              onHide={() => this.setState({ modalShow: false })}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Ask a Question
                </Modal.Title>
              </Modal.Header>
              {loader2&&<Loaders/>}
              {!loader2 && ( 
              <Modal.Body>
                <h4>
                  {" "}
                  Feel free to ask us anything about this tour. A Travel Expert
                  will then get back to you as soon as possible.
                </h4>
                 {!loader2 && ( 
                  <Form noValidate validated={validated} onSubmit={this.askQue}>
                    <Row>
                      <Col lg="12">
                       
                        <Form.Group controlId="validationCustom01">
                          <Label for="exampleEmail">Name</Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Name"
                            className="inputField"
                            value={name}
                            onChange={(e) =>
                              this.setState({ name: e.target.value })
                            }
                          />
                        </Form.Group>
                        <Form.Group controlId="validationCustom02">
                          <Label for="exampleEmail">Email</Label>
                          <Form.Control
                            required
                            type="email"
                            placeholder="Email"
                            className="inputField"
                            value={email}
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                          />
                        </Form.Group>
                        <Form.Group controlId="validationCustom03">
                          <Label for="exampleEmail">Mobile</Label>
                          <Form.Control
                            required
                            type="number"
                            placeholder="Mobile"
                            className="inputField"
                            value={phone}
                            onChange={(e) =>
                              this.setState({ phone: e.target.value })
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                      <Form.Group
                     
                        md="12"
                        controlId="validationCustom03"
                      >
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="text"
                          as="textarea"
                          required
                          rows={3}
                          placeholder="Enter your Question"
                          value={question}
                          onChange={(event) =>
                            this.setState({
                              question: event.target.value,
                            })
                          }
                        />
                      </Form.Group>
                      </Col>
                    </Row>
                    <Button
                     type="submit"
                     style={{ width: "100%" }}
                     className="all-events-btn text-center"
                    >
                      Send Question
                    </Button>
                  </Form>
                )} 
               
                
                
              </Modal.Body>
              )} 
            </Modal>

          )}
        </Row>
      </div>
    );
  }

}

export default OperatorQes;
